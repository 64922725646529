import React from 'react';
import Layout from '../component/common/Layout';
import 'style/animation.scss'


function Setting() {

  return (
    <Layout>
      正在努力开发中
    </Layout>
  );
}

export default Setting;